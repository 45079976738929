<template>
  <div class="personalinfoContainerx1">
    <div class="personMessage" @click="personClick">{{$t('m.messageTitle')}}</div>

    <div v-if="!showMessage" class="messageList">
      <div class="inputStyle">


        <el-select type="text" class="inputSelect" v-model="project_codeStr" @change="getMessageData">



            <el-option
                v-for="item in project_codes"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>

        </el-select>

        <br>
        <el-select type="text" class="inputSelect" v-model="messageType">



          <el-option
                  v-for="item in optionArr"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>

          <!-- <el-option value="学术问题" label="学术问题"></el-option>
          <el-option value="流程问题" label="流程问题"></el-option>
          <el-option value="系统操作问题" label="系统操作问题"></el-option>
          <el-option value="数据问题" label="数据问题"></el-option>
          <el-option value="奖项证书问题" label="奖项证书问题"></el-option>
          <el-option value="其他问题" label="其他问题"></el-option> -->

        </el-select>
        <el-input v-model="messageStr" class="inputStyle1"></el-input>
        <el-button class="sendStyle" @click="sendMessage">{{$t('m.messagetijiao')}}</el-button>
      </div>

      <div v-for="(x, index) in messageList" :key="index" class="messageDetail">
        <div style="color: #4E8AFC; font-size: 16px">
          {{ x.type }}
        </div>
        <div style="color: #535353; font-size: 14px; margin-top: 10px">
          {{ x.message_content }}
        </div>

        <div
          v-if="x.reply_content != ''"
          style="color: #535353; font-size: 14px; margin-top: 10px"
        >
          {{ $t('m.messagehuifu') + x.reply_content }}
        </div>

        <div
          style="background-color: #4E8AFC; height: 1px; margin-top: 10px"
        ></div>
      </div>
    </div>

    <!-- <div v-if="showMessage" class="studentmessageList"></div> -->
  </div>
</template>

<script>
import { sendMessage, getMyMessages ,getUserOpenProjectCode} from "../../api/index";
import { getUserId, getUserType, getProjectCode ,getProjectLanguage } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      user: {},
      user_type: getUserType(),
      imageUrl: "",
      messageList: [],
      messageStr: "",
      showMessage: false,
      messageType: this.$t('m.messageType'),
      timer: "",
      showChinese:getProjectLanguage(),
      optionArr:[ this.$t('m.messagexuesu') 
      , this.$t('m.messageliucheng')
      , this.$t('m.messagexitong')
      , this.$t('m.messageshuju')
      , this.$t('m.messagejiangxiang')
      , this.$t('m.messageqita')
      ],
      project_codes : [],
      project_codeStr : this.$t('m.messageProjectCode')
    };
  },
  mounted() {
    this.getUserOpenProjectCode();
    // this.getMyMessages();

    // this.timer = setInterval(this.showRedMessage, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getMessageData(){
      this.getMyMessages()
    },
    getUserOpenProjectCode(){


      var foreign = 'false'

      if (this.showChinese == 'english'){
        foreign = 'true'
      }
      getUserOpenProjectCode(getUserId(),foreign).then((res) => {
        // console.log(1111,res)
        this.project_codes = res.data
      });
    },
    showRedMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)

        this.traversalData(res.data.data);
      });
      console.log(11);
    },
    traversalData(newData) {
      var haveNoReply = false;
      var newTip = false;

      for (var i = 0; i < newData.length; i++) {
        var message = newData[i];
        if (message.message_reply_id == "") {
          haveNoReply = true;
        }

        if (i < this.messageList.length) {
          var oldmessage = this.messageList[i];

          if (message.message_reply_id == oldmessage.message_reply_id) {
            console.log("是一致的");
          } else {
            console.log("是不一致的");
            newTip = true;
          }
        }
      }

      console.log("是否需要刷新", haveNoReply);
      console.log("是否需要提示新消息", newTip);

      if (haveNoReply == false) {
        clearInterval(this.timer);
      }

      this.messageList = newData;
    },
    clearTime() {
      clearInterval(this.timer);
    },

    getMyMessages() {


      if (this.project_codeStr == this.$t('m.messageProjectCode')){

        this.$message.error(this.$t('m.messageProjectCode'));
        return
      }

      getMyMessages(getUserId(), this.project_codeStr).then((res) => {
        // console.log(1111,res)

        this.messageList = []

        for (var i = 0; i < res.data.data.length; i++) {


          var messageData = res.data.data[i];

          if (this.showChinese == 'english'){

            if (messageData.type == '学术问题'){
              messageData.type = 'About Academic'
            }

            if (messageData.type == '流程问题'){
              messageData.type = 'About Process'
            }
            if (messageData.type == '数据问题'){
              messageData.type = 'About Score'
            }
            if (messageData.type == '系统操作问题'){
              messageData.type = 'About System Operation'
            }
            if (messageData.type == '奖项证书问题'){
              messageData.type = 'About Award and Certificate'
            }
            if (messageData.type == '其他问题'){
              messageData.type = 'Others'
            }
          }

          this.messageList.push(messageData)

        }

      });
    },

    updateUserAvatar(url) {
      updateUserAvatar(getUserId(), url).then((res) => {
        this.getUser();
      });
    },

    sendMessage() {
      // this.timer = setInterval(this.showRedMessage, 5000);


      if (this.project_codeStr == this.$t('m.messageProjectCode')){

        this.$message.error(this.$t('m.messageProjectCode'));
        return
      }

      if (this.messageType == this.$t('m.messageType')) {
        this.$message.error(this.$t('m.messageType'));
        return;
      }
      if (this.messageStr == "") {
        this.$message.error(this.$t('m.qingshuruneirong'));
        return;
      }



      sendMessage(
        getUserId(),
        this.messageType,
        "student",
        this.messageStr,
        this.project_codeStr
      ).then((res) => {
        this.messageStr = "";
        this.messageType = this.$t('m.messageType');

        this.$message({
          message: this.$t('m.messagesendsuccess'),
          type: "success",
        });

        this.getMyMessages();
      });
    },
    personClick() {
      this.showMessage = false;
    },
    studentClick() {
      this.showMessage = true;
    },
    handleEventName(event) {
      if (event) {
        let event_array = event.split(" ");
        return event_array[0];
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="less">
.personalinfoContainerx1 {
  width: 100%;
  margin: 0 auto;
  .window {
    margin-top: 1vh;
    position: relative;
    .windowImg {
      width: 100%;
      height: 60vh;
      left: 0;
      top: 0;
      z-index: -1;
      position: absolute;
    }
    .windowContent {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  .itemLine {
    // position: absolute;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 5vh auto;
    .item {
      width: 45%;
      padding-bottom: 10px;
      border-bottom: 2px solid #4E8AFC;
      color: #4E8AFC;
      font-size: 2.2vh;
      font-family: Montserrat-SemiBold;
      font-weight: bold;
    }
  }
  .personMessage {
    width: 200px;
    height: 40px;
    background: #4E8AFC;
    border-radius: 10px;
    line-height: 40px;
    color: white;
    text-align: center;
    margin-top: 30px;
    margin-left: 20px;
  }
  .messageTitle {
    color: white;
    margin-top: 30px;
    font-family: Montserrat-SemiBold;
  }
  .studentMessage {
    float: left;
    width: 40%;
    height: 60px;
    border-radius: 10px;
    background-color: blue;
    margin-top: 20px;
    font-family: Montserrat-SemiBold;
  }
  .inputStyle {
    width: 100%;
    height: 100px;
    .el-select {
      .el-input__inner {
        border: 2px solid #4E8AFC;
      }
    }
  }
  .inputSelect {
    width: 150px;
    height: 40px;
  }
  .inputStyle1 {
    width: 50%;
    height: 40px;
    margin-left: 20px;
    margin-top: 10px;
    .el-input__inner {
      border: 2px solid #4E8AFC;
      border-radius: 4px 0 0 4px;
    }
  }
  .el-button {
    margin-left: -1px;
    border-radius: 0 4px 4px 0;
    border: 1px solid #4E8AFC;
    background: #4E8AFC;
  }
  .sendStyle {
    background-color: #4E8AFC;
    color: white;
    font-family: Montserrat-SemiBold;
  }
  .messageList {
    width: calc(100% - 40px);
    height: 100%;
    margin-left: 20px;
    background-color: #F3F5F9;
    // padding: 0 20px;
    margin-top: 30px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 10px;
  }
  .list {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .messageDetail {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    font-family: Montserrat-SemiBold;
    // height: 50px;
  }
  .studentmessageList {
    width: calc(80% + 30px);
    height: calc(90% - 90px);
    background-color: #4E8AFC;
    margin-top: 30px;
  }

  .uploadIcon {
    position: absolute;
    // width: 80px;
    // height: 20px;
    border-radius: 7.5px;
    background-color: #4E8AFC;
    margin-top: 120px;
    align-items: center;
  }
  .uploadIconBtn {
    // position: absolute;
    border: none;
    width: 80px;
    height: 15px;
    border-radius: 7.5px;
    background-color: #4E8AFC;
    margin-left: 0px;
    margin-top: 0px;
    // margin: 0px;
    text-align: center;
    color: white;
    font-family: Montserrat-SemiBold;
  }
  .nameItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 30px;
    color: #4E8AFC;
    font-size: 20px;
    font-family: Montserrat-SemiBold;
  }
  .accountItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 60px;
    color: #4E8AFC;
    font-size: 18px;
    font-family: Montserrat-SemiBold;
  }

  .emailItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 90px;
    color: #4E8AFC;
    font-size: 18px;
    font-family: Montserrat-SemiBold;
  }
  .lineItem {
    position: absolute;

    margin-left: 140px;
    margin-top: 130px;
    width: 300px;
    height: 1px;
    background-color: #4E8AFC;
    font-size: 18px;
  }
  .eventItem {
    position: absolute;
    margin-left: 140px;
    margin-top: 160px;
    color: #4E8AFC;
    font-size: 18px;
    font-family: Montserrat-SemiBold;
  }
  .eventInfo {
    margin-top: 10px;
    margin-left: 0px;
    color: #4E8AFC;
    font-size: 13px;
    font-family: Montserrat-SemiBold;
  }
}
</style>
